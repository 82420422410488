import { getPrix } from "../../../services/PrixService";

const price = await getPrix();
let listPrix = {};
listPrix = { ...listPrix, ...price };

export const INDIVIDUELLE = [
  {
    name: "numero-telephone",
    question: "Veuillez saisir le numéro de téléphone du client",
    description: "",
    type: "simple",
    options: [
      // {value: "Veuillez saisir votre numéro de téléphone ", price: ""},
    ],
  },
  {
    name: "email",
    question: "Veuillez saisir votre adresse mail",
    description: "",
    type: "simple",
    options: [
      // {value: "Veuillez saisir votre adresse mail", price: ""},
    ],
  },
  {
    name: "nom",
    question: "Quel est votre nom",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "prenoms",
    question: "Quel est votre prenom(s)",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },

  {
    name: "denomination",
    question: "Quel est le nom de l’entreprise ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "secteur-activite",
    question: "Quelle est votre domaine d'activité ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Commerce general", price: "" },
      { value: "Activités Immobilières", price: "" },
      { value: "Activités Informatiques", price: "" },
      { value: "restauration", price: "" },
      { value: "Agence de voyage", price: "" },
      { value: "Transport VTC", price: "" },
      { value: "Agence de transfert d’argent", price: "" },
      { value: "Cabinet de conseils", price: "" },
      { value: "Autres", price: "" },
    ],
  },
  {
    name: "entreprise-anterieur",
    question: "Avez-vous déjà créé une entreprise ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Oui", price: "" },
      { value: "Non", price: "" },
    ],
  },
  {
    name: "date-reception-certificat",
    question:
      "Quand voulez-vous recevoir votre certificat d'immatriculation (IDU) ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "1 semaine", price: "" },
      { value: "Je ne sais pas encore", price: "" },
    ],
  },
  {
    name: "localisation-ville",
    question: "Dans quelle ville se situe votre société ?",
    description:
      "Il faudra impérativement une adresse physique et postale pour votre organisation.",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Abidjan", price: '' },
      { value: "Hors d'Abidjan", price: '' },
    ],
  },
  {
    name: "localisation-siege",
    question: "Où se situera le siège de votre entreprise ?",
    description:
      "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
    type: "mutiple", //"mutiple",
    options: [
      { value: "A mon domicile", price: "" },
      // { value: "Je domicilie chez Legafrik", price: "" },
      { value: "Je loue un local commercial", price: "" },
      { value: "Je n'ai pas de siège", price: "" },
    ],
  },
  // {
  //   name: "capital-social",
  //   question: "Quel est le montant de votre capital social ?",
  //   description:
  //     "Pas de capital minimum exigé pour une SARL ou une SAS en Côte d'Ivoire",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     { value: "100 000 FCFA", price: "" },
  //     { value: "1 000 000 FCFA", price: "" },
  //     { value: "10 000 000 FCFA", price: "" },
  //     { value: "Autres", price: "" },
  //   ],
  // },
  // {
  //   name: "banque",
  //   question: "Dans quelle Banque, voulez-vous déposer le capital ?",
  //   description: "",
  //   type: "simple", //"mutiple",
  //   options: [
  //     // {value: "", price: ""}
  //   ],
  // },
  // {
  //   name: "gestion-dfe",
  //   question:
  //     "Souhaitez-vous que Legafrik s'occupe de votre formalité de rattachement fiscal et d'obtention de Déclaration Fiscale D'existence (DFE) ?",
  //   description:
  //     "Il est obligatoire d'effectuer une formalité de rattachement au centre d’impôt de votre entreprise dans les 10 jours suivant sa création sous peine d'une amende de 100 000 FCFA.",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     {
  //       value: "Je confie mon rattachement fiscal à Legafrik",
  //       price: 79_000,
  //     },
  //     // {
  //     //   value: "Je confie à Légafrik sans rattachement DFE",
  //     //   price: 30_000,
  //     // },
  //     { value: "Je le gère moi-même", price: "" },
  //   ],
  // },
  {
    name: "gestion-domiciliation",
    question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
    description: "Choisissez une formule",
    type: "mutiple", //"mutiple",
    options: [
      {
        value: `6 mois à ${
          listPrix["Domiciliation"] ? listPrix["Domiciliation"][0].montant : 0
        } FCFA(30 000 F/MOIS)`,
        price: listPrix["Domiciliation"]
          ? listPrix["Domiciliation"][0].montant
          : 0,
      },
      {
        value: `12 mois à ${
          listPrix["Domiciliation"] ? listPrix["Domiciliation"][1].montant : 0
        } FCFA (25 000 F/MOIS)`,
        price: listPrix["Domiciliation"]
          ? listPrix["Domiciliation"][1].montant
          : 0,
      },
      { value: `Je ne suis pas intéressé`, price: "" },
    ],
  },
];
