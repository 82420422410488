import { getPrix } from "../../../services/PrixService";

const price = await getPrix();
let listPrix = {};
listPrix = { ...listPrix, ...price };
export const SUIVIE_DEMANDE = [
  {
    name: "denomination",
    question: "Quel est le nom de l’entreprise ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "secteur-activite",
    question: "Quel est votre secteur d’activités ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Commerce general", price: "" },
      { value: "Activités Immobilières", price: "" },
      { value: "Activités Informatiques", price: "" },
      { value: "restauration", price: "" },
      { value: "Agence de voyage", price: "" },
      { value: "Transport VTC", price: "" },
      { value: "Agence de transfert d’argent", price: "" },
      { value: "Cabinet de conseils", price: "" },
      { value: "Autres", price: "" },
    ],
  },
  {
    name: "entreprise-anterieur",
    question: "Avez-vous déjà créé une entreprise ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Oui", price: "" },
      { value: "Non", price: "" },
    ],
  },
  {
    name: "date-reception-certificat",
    question:
      "Quand voulez-vous recevoir votre certificat d'immatriculation (IDU) ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "1 semaine", price: "" },
      { value: "Je ne sais pas encore", price: "" },
    ],
  },
  {
    name: "localisation-ville",
    question: "Dans quelle ville se situe votre société ?",
    description:
      "Il faudra impérativement une adresse physique et postale pour votre organisation.",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Abidjan", price: '' },
      { value: "Hors d'Abidjan", price: '' },
    ],
  },
  {
    name: "localisation-siege",
    question: "Où se situera votre siège ?",
    description:
      "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
    type: "mutiple", //"mutiple",
    options: [
      { value: "A mon domicile", price: "" },
      { value: "Je domicilie chez Legafrik", price: "" },
      { value: "Je loue un local commercial", price: "" },
      { value: "Je n'ai pas de siège", price: "" },
    ],
  },
  // {
  //   name: "capital-social",
  //   question: "Quel est le montant de votre capital social ?",
  //   description:
  //     "Pas de capital minimum exigé pour une SARL ou une SAS en Côte d'Ivoire",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     { value: "100 000 FCFA", price: "" },
  //     { value: "1 000 000 FCFA", price: "" },
  //     { value: "10 000 000 FCFA", price: "" },
  //     { value: "Autres", price: "" },
  //   ],
  // },
  {
    name: "banque",
    question: "Dans quelle Banque, voulez-vous déposer le capital ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "gestion-domiciliation",
    question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
    description: "Choisissez une formule",
    type: "mutiple", //"mutiple",
    options: [
      {
        value: `6 mois à ${
          listPrix["Domiciliation"] ? listPrix["Domiciliation"][0].montant : 0
        } FCFA(30 000 F/MOIS)`,
        price: listPrix["Domiciliation"]
          ? listPrix["Domiciliation"][0].montant
          : 0,
      },
      {
        value: `12 mois à ${
          listPrix["Domiciliation"] ? listPrix["Domiciliation"][1].montant : 0
        } FCFA (25 000 F/MOIS)`,
        price: listPrix["Domiciliation"]
          ? listPrix["Domiciliation"][1].montant
          : 0,
      },
      { value: `Je ne suis pas intéressé`, price: "" },
    ],
  },

];
