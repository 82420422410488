import { toast } from "react-toastify";
import { Api } from "./Api";
import { decrypt, encrypt } from "../utils/CryptData";

const getPrix = async () => {
  const cachedData = localStorage.getItem("prixOptions"); // Vérifier si les données sont dans le cache

  if (cachedData) {
    return JSON.parse(decrypt(cachedData)); // Retourner les données mises en cache
  }

  try {
    const result = await Api.get(`services/type_demandes?pays_id=1`);
    const keyValueObject = result.reduce((acc, demande) => {
      acc[demande.libelle] = demande.prix;
      return acc;
    }, {});
    window.localStorage.setItem(
      "prixOptions",
      encrypt(JSON.stringify(keyValueObject))
    );

    return keyValueObject;
  } catch (error) {
    toast.error(
      "Une erreur est survenue au niveau du paiement. Veuillez réessayer, svp"
    );
  }
};

export { getPrix };
