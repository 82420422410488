import { getPrix } from "../../../services/PrixService";

const price = await getPrix();
let listPrix = {};
listPrix = { ...listPrix, ...price };

export const DOMICILIATION = [
    {
        name: "numero-telephone",
        question: "Veuillez saisir votre numéro de téléphone (Whatsapp)",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre numéro de téléphone (Whatsapp) ", price: ""},
           
        ]
    },
    {
        name: "email",
        question: "Veuillez saisir votre adresse mail",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre adresse mail", price: ""},
           
        ]
    },
    {
        name: "nom",
        question: "Veuillez saisir votre nom",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre nom", price: ""},
           
        ]
    },
    {
        name: "prenoms",
        question: "Veuillez saisir votre prénom(s)",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre prénom(s)", price: ""},
           
        ]
    },
    {
        name: "denomination",
        question: "Quel est le nom de l’entreprise que vous domicilier ?",
        description: "",
        type: "simple", //"mutiple",
        options: [
          // {value: "", price: ""}
        ],
      },
      {
        name: "secteur-activite",
        question: "Quelle est votre domaine d'activité ?",
        description: "",
        type: "mutiple", //"mutiple",
        options: [
          {value: "BTP", price: ""},
          {value: "Evènementiel", price: ""},
          {value: "Informatiques", price: ""},
          {value: "Finance", price: ""},
          {value: "Formation", price: ""},
          {value: "Autres", price: ""},
        ],
      },
      {
        name: "cabinet-formation",
        question: "Souhaitez vous être accompagné par Legafrik pour obtenir votre agrément FDFP ?",
        description: "",
        type: "mutiple", //"mutiple",
        options: [
          { value: "Je me fais accompagner pour obtenir l'agrément FDFP (100 000 FCFA)", price: 100_000 },
          { value: "Je ne suis pas Intéressé ", price: "" },
        ],
      },
      {
        name: "offre",
        question: "Quelle Offre choisissez-vous ?",
        type: "mutiple", //"mutiple",
        options: [
          {
            value: `6 mois à ${
              listPrix["Domiciliation"] ? listPrix["Domiciliation"][0].montant : 0
            } FCFA(30 000 F/MOIS)`,
            price: listPrix["Domiciliation"]
              ? listPrix["Domiciliation"][0].montant
              : 0,
          },
          {
            value: `12 mois à ${
              listPrix["Domiciliation"] ? listPrix["Domiciliation"][1].montant : 0
            } FCFA (25 000 F/MOIS)`,
            price: listPrix["Domiciliation"]
              ? listPrix["Domiciliation"][1].montant
              : 0,
          },
          { value: `Je ne suis pas intéressé`, price: "" },
        ],
      },
]