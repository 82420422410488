import { getPrix } from "../../../services/PrixService";

const price = await getPrix();
let listPrix = {};
listPrix = { ...listPrix, ...price };
export const FONDATION = [
  {
    name: "numero-telephone",
    question: "Veuillez saisir votre numéro de téléphone (Whatsapp)",
    description: "",
    type:  "simple",
    options : [
        // {value: "Veuillez saisir votre numéro de téléphone (Whatsapp) ", price: ""},
       
    ]
},
{
    name: "email",
    question: "Veuillez saisir votre adresse mail",
    description: "",
    type:  "simple",
    options : [
        // {value: "Veuillez saisir votre adresse mail", price: ""},
       
    ]
},
{
    name: "nom",
    question: "Veuillez saisir votre nom",
    description: "",
    type:  "simple",
    options : [
        // {value: "Veuillez saisir votre nom", price: ""},
       
    ]
},
{
    name: "prenoms",
    question: "Veuillez saisir votre prénom(s)",
    description: "",
    type:  "simple",
    options : [
        // {value: "Veuillez saisir votre prénom(s)", price: ""},
       
    ]
},
{
  name: "denomination",
  question: "Quel est le nom de votre Fondation ?",
  description: "",
  type: "simple", //"mutiple",
  options: [
    // {value: "", price: ""}
  ],
},
{
  name: "localisation-ville",
  question: "Dans quelle ville se situe votre organisation ?",
  description:
    "Il faudra impérativement une adresse physique et postale pour votre organisation.",
  type: "mutiple", //"mutiple",
  options: [
    { value: "Abidjan", price: "" },
    { value: "Hors d'Abidjan", price: "" },
  ],
},
{
  name: "localisation-siege",
  question: "Où se situera le siège de votre organisation ?",
  description:
    "Il faudra impérativement une adresse physique et postale pour votre organisation.",
  type: "mutiple", //"mutiple",
  options: [
    { value: "A mon domicile", price: "" },
    // { value: "Je domicilie à Legafrik (30 000 FCFA/mois)", price: "" },
    { value: "Je loue un local commercial", price: "" },
    { value: "Je n'ai pas de siège", price: "" },
  ],
},
{
  name: "secteur-activite",
  question: "Quel est votre domaine d’action ?",
  description: "",
  type: "mutiple", //"mutiple",
  options: [
    { value: "Santé", price: "" },
    { value: "Action sociale", price: "" },
    { value: "Humanitaire", price: "" },
    { value: "Environnement", price: "" },
    { value: "Art & Culture", price: "" },
    { value: "Religion", price: "" },
    { value: "Autres", price: "" },
  ],
},
// {
//   name: "cabinet-formation",
//   question:
//     "Souhaitez vous être accompagné par Legafrik pour obtenir votre agrément FDFP ?",
//   description: "",
//   type: "mutiple", //"mutiple",
//   options: [
//     {
//       value:
//         "Je me fais accompagner pour obtenir l'agrément FDFP (100 000 FCFA)",
//       price: 100_000,
//     },
//     { value: "Je ne suis pas Intéressé ", price: "" },
//   ],
// },
{
  name: "est-president",
  question: "Êtes vous le président ?",
  description: "",
  type: "mutiple", //"mutiple",
  options: [
    { value: "Oui", price: "" },
    { value: "Non", price: "" },
  ],
},
// {
//   name: "offre",
//   question: "Quelle Offre choisissez-vous ?",
//   type: "mutiple", //"mutiple",
//   options: [
//     { value: "Abidjan (159 000 FCFA)", price: 159_000 },
//     { value: "Interieur du pays (199 000 FCFA)", price: 199_000 },
//   ],
// },
{
  name: "gestion-domiciliation",
  question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
  description: "Choisissez une formule",
  type: "mutiple", //"mutiple",
  options: [
    {
      value: `6 mois à ${
        listPrix["Domiciliation"] ? listPrix["Domiciliation"][0].montant : 0
      } FCFA(30 000 F/MOIS)`,
      price: listPrix["Domiciliation"]
        ? listPrix["Domiciliation"][0].montant
        : 0,
    },
    {
      value: `12 mois à ${
        listPrix["Domiciliation"] ? listPrix["Domiciliation"][1].montant : 0
      } FCFA (25 000 F/MOIS)`,
      price: listPrix["Domiciliation"]
        ? listPrix["Domiciliation"][1].montant
        : 0,
    },
    { value: `Je ne suis pas intéressé`, price: "" },
  ],
},
];
