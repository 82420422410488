import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Components } from "../components";
import { useNavigate, useParams } from "react-router-dom";
import { Hooks } from "../hooks";
import { Data } from "../dataOffline";
import { Services } from "../services";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import { CinetPaySerivce } from "../services/CinetpayService";
import { dataDataGreffeRccm } from "../services/DatagreffeService";
// import { Utils } from "../utils";
import Spinner from "../components/Spinner";
import ModalPaiment from "../components/ModalPaiment";
import { getPrix } from "../services/PrixService";
export function DemandeCreateViewInitiale(props) {
  // console.log("DemandeCreateViewInitiale", props);
  const abortController = useMemo(() => new AbortController(), []);

  const { ChampsDemande } = useMemo(() => Data, []);

  const { id, paraPays, type_demande } = useParams();
  const navigate = useNavigate();
  const [moyen_paiements, setMoyens_payements] = useState([]);
  const [moyen_paiement, setMoyen_paiement] = useState("");
  const [pays, setPays] = useState("");
  const [champsDemandeData, setChampsDemandeData] = useState([]);
  const [champsDemandeObject, setChampsDemandeObject] = useState({});
  const [domiciliationData, setDomiciliationData] = useState({});

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [statePaiement, setStatePaiement] = useState(null);

  const [demandeId, setDemandeId] = useState("");
  const [tempData, setTempData] = useState("b");
  const [stateLoad, setStateLoad] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [country, setCountry] = useState();
  const [dataUse, setDataUse] = useState();
  const [type_demande_id, setType_demande_id] = useState({});

  const [step, setStep] = useState(0);
  const [montantSiege, setMontantSiege] = useState(0);
  const [montantCession, setMontantCession] = useState(0);
  const [montantCapital, setMontantCapital] = useState(0);
  const [prixAnnonce, setPrixAnnonce] = useState(0);
  const [listeDemande, setListeDemande] = useState("");
  const [prix, setPrix] = useState([]);
  const [listPrix, setListPrix] = useState({});

  /* Formation */

  let tabFormation = {
    name: "cabinet-formation",
    question:
      "Souhaitez vous être accompagné par Legafrik pour obtenir votre agrément FDFP ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      {
        value: `Je me fais accompagner pour obtenir l'agrément FDFP (${
          listPrix.formation ? listPrix.formation[0].montant : 0
        })`,
        price: listPrix.formation ? listPrix.formation[0].montant : 0,
      },
      { value: "Je ne suis pas Intéressé ", price: "" },
    ],
  };

  /* Transit */

  let tabTrasit = {
    name: "cabinet-transit",
    question:
      "Souhaitez-vous d'être accompagné pour l'obtention du code import/export ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      {
        value: `Je me fais accompagner pour obtenir le code Import/Export (${
          listPrix.transit ? listPrix.transit[0].montant : 0
        } FCFA) `,
        price: listPrix.transit ? listPrix.transit[0].montant : 0,
      },
      { value: "Je ne suis pas Intéressé ", price: "" },
    ],
  };

  /* Transport */

  let tabTransport = {
    name: "transport",
    question:
      "Souhaitez-vous être accompagné par Legafrik pour vous immatriculer au Registre des transporteurs ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      {
        value: `Je me fais accompagner pour mon immatriculation (${
          listPrix.transport ? listPrix.transport[0].montant : 0
        } FCFA) `,
        price: listPrix.transport ? listPrix.transport[0].montant : 0,
      },
      { value: "Je ne suis pas Intéressé ", price: "" },
    ],
  };

  /* Option domicilie à Legafrik */
  let optionDomicili = {
    value: `Je domicilie à Legafrik ${
      paraPays.toLowerCase().includes("ca")
        ? "(20 000 FCFA/mois)"
        : paraPays.toLowerCase().includes("rdc") && type_demande === "sarl"
        ? "(45 USD/mois)"
        : paraPays.toLowerCase().includes("rdc") &&
          (type_demande === "sarlu" ||
            type_demande === "sas" ||
            type_demande === "sasu")
        ? "(32 USD/mois)"
        : ""
    } `,
    price: "",
  };

  /* Nouveau siege */

  let nouveau_siege = {
    name: "nouveau-siege",
    question: "",
    description: "",
    // type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  };

  /* Nouveau nom entreprise */

  let nouveau_nom_entreprise = {
    name: "nouveau-nom-entreprise",
    question: "",
    description: "Veuillez comfirmer le nom  actuel de votre entreprise",
    // type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  };

  /*
   * part ceder
   */

  let vendeur_part = {
    name: "vendeur-part",
    question: "",
    description: "Cession de parts ou actions ",
    // type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  };

  /*
   * nouveau president
   */

  let nouveau_president = {
    name: "nouveau-president",
    question: "",
    description:
      "Veuillez entrer les informations du nouveau président ou gerant",
    // type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  };

  /*
   * nouvelle_activite
   */
  let nouvelle_activite = {
    name: "nouvelle-activite",
    question: "",
    description: "Confirmez la liste actuelle de vos activités",
    // type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  };

  /*
   * nouvelle_capital
   */
  let nouvelle_acapital = {
    name: "nouvelle-capital",
    question: " ",
    description: "Quel  est le montant du nouveau capital social ?",
    // type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  };

  const useDemande = Hooks.useDemande();
  const usePaiement = Hooks.usePaiement();
  // console.log("usePaiement",usePaiement);

  const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_KEY);

  const dataUser = JSON.parse(window.localStorage.getItem("dataUser"));

  const handleNextClick = (e, name, value) => {
    e.preventDefault();
    // console.log(" handleNextClick", name, value);
    if (name === "associes") {
      let som = 0;
      const dataPars = JSON.parse(value);

      dataPars.map((associe) => {
        if (associe["montant-part-vendu"]) {
          som = som + JSON.parse(associe["montant-part-vendu"]);
        }
      });
      const result = Math.ceil(som * 0.03);
      if (result > 22000) {
        setMontantCession(Math.ceil(som * 0.03));
      } else {
        setMontantCession(22000);
      }
    }

    if (name === "nouveau-siege") {
      setMontantSiege(value);
    }
    if (name === "nouvelle-capital") {
      setMontantCapital(value);
    }

    if (name === "insertion-journal") {
      setPrixAnnonce(value);
    }

    let champsDemandeObjectCopy = { ...champsDemandeObject };

    champsDemandeObjectCopy[name] = value;

    setTempData(value);
    setChampsDemandeObject(champsDemandeObjectCopy);

    setStep(step + 1);
  };

  const handleNextClicked = async (e, name, value) => {
    e.preventDefault();
    setStateLoad(true);
    if (value) {
      const result = await dataDataGreffeRccm(value.rccm);

      const champsDemandeObjectCopy = { ...champsDemandeObject };

      champsDemandeObjectCopy["denomination"] =
        result.response.data[0].denomination;
      champsDemandeObjectCopy["capital-social"] =
        result.response.data[0].capital;
      champsDemandeObjectCopy["localisation-siege"] =
        result.response.data[0].siege;
      champsDemandeObjectCopy["activites"] = result.response.data[0].activites;
      champsDemandeObjectCopy["nouvelle-forme-juridique"] =
        result.response.data[0].forme_juridique;
      champsDemandeObjectCopy["numero-rccm"] = result.response.data[0].rccm;
      champsDemandeObjectCopy["ville-immatriculation"] =
        result.response.data[0].ville_immatriculation;
      // console.log("champsDemandeObjectCopy", champsDemandeObjectCopy);
      setTempData(result.response.data[0]);
      setChampsDemandeObject(champsDemandeObjectCopy);

      setStep(step + 7);
      setStateLoad(false);
    }
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    if (step === 0 || useDemande.isDisabled) return;
    setStep(step - 1);
  };

  const handleModalClose = (e) => {
    setIsModalOpen(false);
    setMoyen_paiement("");
  };

  const handleSetAmount = (val) => {
    // console.log("handleSetAmount", val);
    usePaiement.setMontant(val);
    useDemande.setMontant_total(val);
  };

  const handleValidateClick = async (e) => {
    setIsConfirmModalOpen(true);
  };

  const handleStatusPaiement = (statuts) => {
    console.log("statuts", statuts);
    if (statuts) {
      navigate("/new-passwords", {
        state: { token: `${dataUser.token}`, email: `${dataUser.email}` },
      });
    }
  };

  const handleStateReturnChange = (statuts) => {
    // console.log("statuts", statuts);
    setIsEditModalOpen(false);
    setStatePaiement(statuts);
  };

  const createDemande = async (tk) => {
    // console.log("useDemande.isDisabled1", useDemande);
    // console.log("tk", tk);

    if (useDemande.isDisabled) return;
    useDemande.setIsDisabled(true);
    // console.log("useDemande.isDisabled2", useDemande.isDisabled);

    try {
      const payload = {
        type_demande_id: type_demande_id.id,
        dossier_id: dataUser.dossier_id,
        pays_id: pays.id,
        champs_demande: JSON.stringify(champsDemandeObject),
        montant_total: useDemande.montant_total,
      };

      const { demande } = await Services.DemandeService.creates(
        tk,
        JSON.stringify(payload),
        abortController.signal
      );

      // console.log("demande", demande);
      useDemande.setIsDisabled(false);
      setDemandeId(demande.id);
      setStep(step + 1);

      await Services.HubspotService.createLeads(
        tk,
        JSON.stringify(payload),
        abortController.signal
      );
    } catch (error) {
      //console.log("error", error);
      useDemande.setIsDisabled(false);
      if ("messages" in error) return; //Toast
    }
  };

  const handleConfirmClick = async () => {
    const dataUser = JSON.parse(window.localStorage.getItem("dataUser"));
    createDemande(dataUser.token);
    setDataUse(dataUser.token);

    setIsConfirmModalOpen(false);
  };

  // console.log('setDataUse',dataUse.token);
  

  const handlePaymentSubmit = async (e, payment_data) => {
    // console.log("payment_data", payment_data);
    e.preventDefault();

    if (payment_data.id === 3) {
      if (usePaiement.isDisabled) return;
      useDemande.setIsDisabled(true);
      try {
        const payload = {
          type_demande_id: type_demande_id.id,
          dossier_id: dataUser.dossier_id,
          pays_id: pays.id,
          champs_demande: JSON.stringify(champsDemandeObject),
          montant_total: useDemande.montant_total,
        };
        // console.log("leads", payload);

        const { demande } = await Services.DemandeService.creates(
          dataUser.token,
          JSON.stringify(payload),
          abortController.signal
        );

        // console.log("demande", demande);
        useDemande.setIsDisabled(false);
        setDemandeId(demande.id);
        // setStep(step + 1);

        await Services.HubspotService.createLeads(
          dataUser.token,
          JSON.stringify(payload),
          abortController.signal
        );
        navigate("/new-passwords", {
          state: { token: `${dataUser.token}`, email: `${dataUser.email}` },
        });
      } catch (error) {
        //console.log("error", error);
        useDemande.setIsDisabled(false);
        if ("messages" in error) return; //Toast
      }
    }

    // console.log("demandeId", demandeId);

    if (usePaiement.isDisabled) return;
    let payload = {
      type_paiement_id: 1,
      moyen_paiement_id: payment_data.id ? payment_data.id : moyen_paiement.id,
      demande_id: demandeId,
      montant: usePaiement.montant,
    };
    payment_data["pays"] = JSON.stringify(pays);

    try {
      usePaiement.setIsDisabled(true);

      payload["payment_data"] = JSON.stringify(payment_data);
      payload["moyen_paiement_id"] = payment_data.id
        ? payment_data.id
        : moyen_paiement.id;
      // console.log('payload',payload);
      await Services.PaiementService.creates(
        dataUse,
        JSON.stringify(payload),
        abortController.signal
      );

      navigate("/new-passwords", {
        state: { token: `${dataUser.token}`, email: `${dataUser.email}` },
      });
    } catch (error) {
      usePaiement.setIsDisabled(false);
      if ("messages" in error) return; //Toast
    }
  };

  const handlePaymentMethodClick = async (e, moyen_paiement) => {
    e.preventDefault();

    setIsModalOpen(true);
    setMoyen_paiement(moyen_paiement);

    const payment_data = {
      id: moyen_paiement.id,
      currency: pays.monnaie,
      moyen_paiement_id: moyen_paiement.id,
      description: "This is test payment",
    };
    let money = 0;
    if (moyen_paiement.libelle !== "Payer plus tard") {
      money = usePaiement.montant;
    }

    const payload = {
      id: Math.round(Math.random() * 1000000).toString(),

      amount: money,
      payment_data: payment_data,
      moyen_paiement_id: moyen_paiement.id,
    };

    if (moyen_paiement.libelle.includes("Mobile")) {
      setIsEditModalOpen(true);

      if (statePaiement) {
        await handlePaymentSubmit(e, payment_data);
      }
    } else {
      setIsEditModalOpen(false);

      await handlePaymentSubmit(e, payment_data);
    }
    if (moyen_paiement.libelle.includes("plus tard")) {
      await handlePaymentSubmit(e, payload);
      // navigate("/new-password");
    }
  };

  const initialize = useCallback(async () => {
    window.localStorage.removeItem("cst");
    // const dataUser = JSON.parse(window.localStorage.getItem("dataUser"));

    try {
      let country_code = null;

      if (paraPays === "ma" || paraPays === "MA") {
        country_code = "MR";
      } else if (paraPays === "sn" || paraPays === "SN") {
        country_code = "SN";
      } else if (paraPays === "bn" || paraPays === "BN") {
        country_code = "BN";
      } else if (paraPays === "bf" || paraPays === "BF") {
        country_code = "BF";
      } else if (paraPays === "rdc" || paraPays === "RDC") {
        country_code = "RDC";
      } else if (paraPays === "tg" || paraPays === "TG") {
        country_code = "TG";
      } else if (paraPays === "gn" || paraPays === "GN") {
        country_code = "GN";
      } else if (paraPays === "ca" || paraPays === "CA") {
        country_code = "CM";
      } else {
        country_code = "CI";
      }

      const datasPays = await Services.PaysService.getAlls(
        abortController.signal
      );

      let country = datasPays.pays.find(
        (country_) => country_.code === country_code
      );

      const typeDemandes = await Services.PaysService.getTypeDemandess(
        country.id,
        abortController.signal
      );

      // console.log("type_demande", type_demande);
      let type_demandees_user = "";
      if (type_demande === "sarl") {
        type_demandees_user = "Création d'une SARL";
      } else if (type_demande === "sarlu") {
        if (pays === "sn") {
          type_demandees_user = "Création d'une SUARL";
        } else {
          type_demandees_user = "Création d'une SARLU";
        }
      } else if (type_demande === "SCOOP" || type_demande === "scoop") {
        type_demandees_user = "Création d'une SCOOP";
      } else if (type_demande === "sas") {
        type_demandees_user = "Création d'une SAS";
      } else if (type_demande === "ong") {
        type_demandees_user = "Création d'une ONG";
      } else if (type_demande === "association") {
        type_demandees_user = "Création d'une Association";
      } else if (
        type_demande === "depot_marque" ||
        type_demande === "depot-marque"
      ) {
        type_demandees_user = "Dépôt de Marque";
      } else if (type_demande === "domiciliation") {
        type_demandees_user = "Domiciliation";
      } else if (type_demande === "individuelle") {
        type_demandees_user = "Création d'une entreprise individuelle";
      } else if (type_demande === "fondation") {
        type_demandees_user = "Création d'une Fondation";
      } else if (type_demande === "sasu") {
        type_demandees_user = "Création d'une SASU";
      } else if (type_demande === "sci-commerciale") {
        type_demandees_user = "Création d'une SCI commerciale";
      } else if (type_demande === "sci-civile") {
        type_demandees_user = "Création d'une SCI civile";
      } else if (type_demande.includes("statutaire")) {
        type_demandees_user = "Modification statutaire";
      } else {
        type_demandees_user = "Création d'une SA";
      }

      let filtre_demanded = typeDemandes.type_demandes.find(
        (demande) => demande.libelle === type_demandees_user
      );
      setType_demande_id(filtre_demanded);
      useDemande.setType_demande_id(type_demande_id.id);

      setPays(country);
      setCountry(country.libelle);

      const { moyen_paiements } = await Services.MoyenPaiementService.getAlls(
        abortController.signal
      );
      setMoyens_payements(moyen_paiements);

      const price = await getPrix();

      setListeDemande((prevListeDemande) => ({
        ...prevListeDemande,
        ...price, // Merge with previous state
      }));

      setListPrix({
        ...listPrix,
        transport: listeDemande["CARTE DE TRANSPORT-TRANSPORTEUR"],
        formation: listeDemande["Demande d'agréement FDFP"],
        transit: listeDemande["CODE IMPORT/ EXPORT"],
      });
    } catch (error) {
      if ("messages" in error) return;
      //toast
    }
  }, [abortController, id]);

  useEffect(() => {
    if (
      listeDemande &&
      type_demande_id?.libelle &&
      listeDemande[type_demande_id.libelle]
    ) {
      setPrix((prevListeDemande) => {
        if (!prevListeDemande.includes(listeDemande[type_demande_id.libelle])) {
          return [
            ...prevListeDemande,
            ...listeDemande[type_demande_id.libelle],
          ];
        }
        return prevListeDemande;
      });
    }
  }, [listeDemande]);


  useEffect(() => {
    initialize();
  }, [initialize]);

  /* Commune de l'entreprise */

  useEffect(() => {
    // console.log('tempData',tempData);

    let reform = champsDemandeData.map((data) => {
      let dataOption = data.options.filter(
        (val, index) => !val.value.includes("domicilie à Legafrik")
      );
      return { ...data, options: [...dataOption] };
    });

    // reform.filter((data) => !data.name.includes("gestion-dfe"));

    if (typeof tempData === "string" && tempData === "Abidjan") {
      let champsDemandeDataCopy = reform.map((data, index) => {
        if (data.name === "localisation-siege") {
          data.options.unshift(optionDomicili);
        }
        return { ...data, options: [...data.options] };
      });

      if (
        type_demande.includes("individuelle") ||
        type_demande.includes("statutaire")
      ) {
        champsDemandeDataCopy = champsDemandeDataCopy.filter(
          (item) => item.name !== "gestion-dfe"
        );
      }
      //   // console.log('champsDemandeDataCopy',champsDemandeDfe);

      setChampsDemandeData(champsDemandeDataCopy);
    }
    if (typeof tempData === "string" && tempData === "Hors d'Abidjan") {
      reform.map((data) => {
        let dataOption = data.options.filter(
          (val, index) => !val.value.includes("domicilie à Legafrik")
        );
        return { ...data, options: [...dataOption] };
      });
      const champsDemandeDfe = reform.filter(
        (data) => !data.name.includes("gestion-dfe")
      );

      setChampsDemandeData(champsDemandeDfe);
    }
  }, [tempData]);

  /* fin Commune de l'entreprise */

  function remove_duplicates_es6(arr) {
    return arr.filter((obj, index) => {
      return index === arr.findIndex((o) => obj.name === o.name);
    });
  }

  /* Ajout et suppression des options Activite en fonction du choix de l'utilisation*/

  useEffect(
    () => {
      // if (!pays.code) return;
      // console.log("tempData", tempData);
      // console.log("pays.code", pays.code);
      // const CODE_PAYS = pays;
      const sectuerActivite = champsDemandeObject["secteur-activite"];

      const reform = champsDemandeData.map((data) => {
        return { ...data, options: [...data.options] };
      });

      if (pays.code?.includes("CI")) {
        if (
          sectuerActivite !== undefined &&
          typeof tempData === "string" &&
          !tempData.includes("formation") &&
          !tempData.includes("Transit") &&
          !tempData.includes("Transport")
        ) {
          const champsDemandeDataCopyed = reform.filter(
            (data) =>
              !data.name.includes("cabinet-formation") &&
              !data.name.includes("cabinet-transit") &&
              !data.name.includes("transport")
          );

          setChampsDemandeData(champsDemandeDataCopyed);
        }
        if (
          sectuerActivite !== undefined &&
          typeof tempData === "string" &&
          tempData.includes("formation")
        ) {
          const reformat = reform.filter(
            (data) =>
              !data.name.includes("cabinet-transit") &&
              !data.name.includes("transport") &&
              !data.name.includes("cabinet-formation")
          );
          reformat.push(tabFormation);
          setChampsDemandeData(reformat);
        }

        if (
          sectuerActivite !== undefined &&
          typeof tempData === "string" &&
          tempData.includes("Transit")
        ) {
          const reformated = reform.filter(
            (data) =>
              !data.name.includes("cabinet-formation") &&
              !data.name.includes("transport") &&
              !data.name.includes("cabinet-transit")
          );
          reformated.push(tabTrasit);
          setChampsDemandeData(reformated);
        }

        if (
          sectuerActivite !== undefined &&
          typeof tempData === "string" &&
          tempData.includes("Transport")
        ) {
          const reformatedTransport = reform.filter(
            (data) =>
              !data.name.includes("cabinet-formation") &&
              !data.name.includes("cabinet-transit") &&
              !data.name.includes("transport")
          );
          reformatedTransport.push(tabTransport);
          setChampsDemandeData(reformatedTransport);
        }
      }
    },
    [champsDemandeObject["secteur-activite"]],
    tempData,
    champsDemandeData,

    pays.code
  );

  useEffect(() => {
    const myTab = JSON.parse(window.localStorage.getItem("cst"));
    let tabChampdata = champsDemandeData.map((data) => {
      return { ...data, options: [...data.options] };
    });

    if (pays.code?.includes("CI")) {
      let tableObject = [];
      if (myTab) {
        if (myTab.includes("Je change le nom de  mon entreprise")) {
          tableObject.push(nouveau_nom_entreprise);
        }

        if (myTab.includes("Je modifie mes activités")) {
          tableObject.push(nouvelle_activite);
        }

        if (myTab.includes("Je transfère mon siège social")) {
          tableObject.push(nouveau_siege);
        }

        if (myTab.includes("augmente mon capital social")) {
          tableObject.push(nouvelle_acapital);
        }

        if (myTab.includes("Je nomme un nouveau Gérant/Président")) {
          tableObject.push(nouveau_president);
        }

        if (myTab.includes("Je cède des parts")) {
          tableObject.push(vendeur_part);
        }

        tabChampdata.push(...tableObject);

        tabChampdata = remove_duplicates_es6(tabChampdata);

        if (!myTab.includes("Je change le nom de  mon entreprise")) {
          tabChampdata = tabChampdata.filter(
            (item) => item.name !== "nouveau-nom-entreprise"
          );
        }

        if (!myTab.includes("Je modifie mes activités")) {
          tabChampdata = tabChampdata.filter(
            (item) => item.name !== "nouvelle-activite"
          );
        }
        if (!myTab.includes("Je transfère mon siège social")) {
          tabChampdata = tabChampdata.filter(
            (item) => item.name !== "nouveau-siege"
          );
          setMontantSiege(0);
        }

        if (!myTab.includes("augmente mon capital social")) {
          tabChampdata = tabChampdata.filter(
            (item) => item.name !== "nouvelle-capital"
          );
          setMontantCapital(0);
        }

        if (!myTab.includes("Je nomme un nouveau Gérant/Président")) {
          tabChampdata = tabChampdata.filter(
            (item) => item.name !== "nouveau-president"
          );
        }

        if (!myTab.includes("Je cède des parts")) {
          tabChampdata = tabChampdata.filter(
            (item) => item.name !== "vendeur-part"
          );
          setMontantCession(0);
        }

        // setMyTabData(tabChampdata);

        // console.log("tabData", tabData);

        setChampsDemandeData(tabChampdata);
      }
    }
  }, [champsDemandeObject["modification-du-statut"]]);

  useEffect(() => {
    const localisationSiege = champsDemandeObject["localisation-siege"];

    if (
      localisationSiege !== undefined &&
      !localisationSiege.includes("Legafrik")
    ) {
      champsDemandeData.forEach((data, index) => {
        // console.log("champsDemandeData10", data);
        if (data.name !== "gestion-domiciliation") return;
        setDomiciliationData({ index, object: data });
      });
      const champsDemandeDataCopy = champsDemandeData.map((data) => {
        return { ...data, options: [...data.options] };
      });
      const domiciliationIndex = champsDemandeDataCopy.findIndex(
        (data) => data.name === "gestion-domiciliation"
      );

      if (domiciliationIndex < 0) return;

      champsDemandeDataCopy.splice(domiciliationIndex, 1);
      setChampsDemandeData(champsDemandeDataCopy);
    } else {
      const domiciliationDataIndex = domiciliationData.index;
      // console.log("champsDemandeData11", champsDemandeData);

      if (!domiciliationDataIndex || domiciliationDataIndex < 0) return;
      const champsDemandeDataCopy = champsDemandeData.map((data) => {
        return { ...data, options: [...data.options] };
      });

      champsDemandeDataCopy.splice(
        domiciliationDataIndex,
        0,
        domiciliationData.object
      );

      setChampsDemandeData(champsDemandeDataCopy);
      setDomiciliationData({});
    }
  }, [champsDemandeObject["localisation-siege"]]);

  useEffect(() => {
    if (!pays.code) return;

    const LIBELLE = type_demande;
    // console.log("useTypeDemande", LIBELLE);

    const CODE_PAYS = pays.code.toUpperCase();
    // console.log("ChampsDemande", ChampsDemande[CODE_PAYS]);

    if (LIBELLE.includes("SARL") || LIBELLE.includes("sarl"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SARL]);
    if (LIBELLE.includes("SARLU") || LIBELLE.includes("sarlu"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SARLU]);
    if (LIBELLE.includes("SUARL") || LIBELLE.includes("suarl"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SARLU]);
    if (LIBELLE.includes("SAS") || LIBELLE.includes("sas"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SAS]);
    if (LIBELLE.includes("SASU") || LIBELLE.includes("sasu"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SASU]);

    if (LIBELLE.includes("SCI-CIVILE") || LIBELLE.includes("sci-civile"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SCI]);
    if (
      LIBELLE.includes("SCI-COMMERCIALE") ||
      LIBELLE.includes("sci-commerciale")
    ) {
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SAS]);
    }

    if (LIBELLE.includes("ONG") || LIBELLE.includes("ong"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].ONG]);
    if (LIBELLE.includes("Association") || LIBELLE.includes("association"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].ASSOCIATION]);
    if (LIBELLE.includes("Fondation") || LIBELLE.includes("fondation"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].FONDATION]);
    if (LIBELLE.includes("Domiciliation") || LIBELLE.includes("domiciliation"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].DOMICILIATION]);
    if (LIBELLE.includes("Marque") || LIBELLE.includes("marque"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].DEPOT_MARQUE]);
    if (LIBELLE.includes("Recouvrement") || LIBELLE.includes("recouvrement"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].RECOUVREMENT]);

    if (LIBELLE.includes("statutaire") || LIBELLE.includes("Statutaire"))
      setChampsDemandeData([
        ...ChampsDemande[CODE_PAYS].MODIFICATION_STATUTAIRE,
      ]);
    if (LIBELLE.includes("SCOOP") || LIBELLE.includes("scoop"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SAS]);

    if (LIBELLE.includes("individuelle"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].INDIVIDUELLE]);

    setStep(1);
  }, [type_demande, ChampsDemande, pays.code]);

  return (
    <div className="col-span-12 mt-8">
      <h2 className="intro-y text-lg font-medium">
        Nouvelle commande " {country} entreprise{" "}
        {type_demande.toLocaleUpperCase()}"
      </h2>
      {champsDemandeData && champsDemandeData.length > 0 ? (
        <div className="intro-y box py-10 sm:py-20 mt-">
          <Components.ProgressBar value={step} max={champsDemandeData.length} />
          {stateLoad ? (
            <Spinner />
          ) : (
            <>
              {champsDemandeData.map((champsDemandeDataItem, index) => {
                return (
                  <Fragment key={index}>
                    <Components.DemandeStep
                      step={step}
                      index={index + 1}
                      length={champsDemandeData.length}
                      demande={type_demande}
                      pays={paraPays}
                      champsDemandeData={champsDemandeDataItem}
                      handleNextClick={handleNextClick}
                      handleBackClick={handleBackClick}
                      handleNextClicked={handleNextClicked}
                      champsDemandeObject={champsDemandeObject}
                    />
                  </Fragment>
                );
              })}
            </>
          )}

          {champsDemandeData.length > 0 &&
          step - champsDemandeData.length === 1 ? (
            <Components.Cart
              productList={champsDemandeData}
              selectedProductList={Object.keys(champsDemandeObject).map(
                (key) => {
                  return { [key]: champsDemandeObject[key] };
                }
              )}
              handleRemoveProduct={null}
              handleBackClick={handleBackClick}
              handleValidateClick={handleValidateClick}
              isDisabled={useDemande.isDisabled}
              setAmount={handleSetAmount}
              amount={usePaiement.montant}
              currency={pays.monnaie}
              demande={type_demande}
              paraPays={paraPays}
              price={prix ?? 0}
              prixAnnonce={prixAnnonce}
              montantSiege={montantSiege}
              montantCapital={montantCapital}
              montantCession={montantCession}
              moyen_paiements={moyen_paiements}
              handlePaymentClick={handlePaymentMethodClick}
            />
          ) : null}

          {champsDemandeData.length > 0 &&
          step - champsDemandeData.length === 2 ? (
            <Components.Payment
              moyen_paiements={moyen_paiements}
              currency={pays.monnaie}
              handlePaymentClick={handlePaymentMethodClick}
              amount={usePaiement.montant}
            />
          ) : null}
        </div>
      ) : (
        <div className="flex items-center justify-center text-xl font-medium intro-y box py-10 sm:py-20">
          Formulaire encours de construction
        </div>
      )}
      {isModalOpen &&
      (moyen_paiement.libelle.toLowerCase().includes("Stripe") ||
        moyen_paiement.libelle.toLowerCase().includes("carte")) ? (
        <Components.Modal
          handleModalClose={handleModalClose}
          title={"Paiement par Stripe"}
          handleModalValidate={null}
        >
          <Elements stripe={stripePromise} usePaiement={usePaiement}>
            <Components.Stripe.CheckoutForm
              usePaiement={usePaiement}
              handlePaymentSubmit={handlePaymentSubmit}
              currency={pays.monnaie}
              demande={demandeId}
              dataUse={dataUse}
            />
          </Elements>
        </Components.Modal>
      ) : null}
      {isConfirmModalOpen ? (
        <Components.Modal
          handleModalClose={() => setIsConfirmModalOpen(false)}
          title={"Confirmation"}
          handleModalValidate={() => handleConfirmClick()}
          isControlVisible={true}
        >
          Voulez-vous confirmer votre commande ?
        </Components.Modal>
      ) : null}

      {isEditModalOpen ? (
        <Components.Modal
          handleModalClose={() => setIsEditModalOpen(false)}
          handleModalValidate={() => setIsEditModalOpen(false)}
          title={"Paiement"}
        >
          <ModalPaiment
            dataPaiementId={demandeId}
            currency={pays.monnaie}
            moyen_paiements={moyen_paiements}
            handlePaymentClick={handlePaymentMethodClick}
            paymentAmount={usePaiement.montant}
            isDisabled={useDemande.isDisabled}
            statusPaiement={handleStatusPaiement}
            handleStateReturnChange={handleStateReturnChange}
            dataUser={dataUser.token}
            statusDemande={"offline"}
          />
        </Components.Modal>
      ) : null}
    </div>
  );
}
