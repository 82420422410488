import { useCallback, useEffect, useState } from "react";
// import * as Icons from "react-feather";

import Alert from "react-bootstrap/Alert";
// import { Api } from "../services/Api";
// import { toast } from "react-toastify";

export function Cart(props) {
  // console.log("Cart", props);
  const { selectedProductList, productList, demande, prixAnnonce } = props;

  const [dynamicProductList, setDynamicProductList] = useState([]);
  const [netAmount, setNetAmount] = useState("");

  const getTaxAmount = (currency) => {
    if (currency === "MAD") return 20;
    return 0;
  };

  const initalize = useCallback(async () => {
    let pricedProductList = [];

    if (demande.includes("statutaire")) {
      let amount = 0;
      let prixAnnonced = 0;

      let tabElement = [
        {
          name: "redaction-pv",
          value: "Rédaction du PV d’AGE",
        },
        {
          name: "mise-a-jour-status",
          value: "Mise à jour des statuts et annexes ",
        },
        {
          name: "convention-foncier",
          value: "Enregistrement à la conservation foncière",
        },
        {
          name: "formalite-greffe",
          value: "Formalité au greffe ",
        },
      ];

      // let champsDemandeDfe = [...champsDemandeDataCopy];

      // if (!type_demande.includes("individuelle") && !type_demande.includes("statutaire")) {
      //   champsDemandeDfe = [...champsDemandeDataCopy, dfe];
      // }

      const montSiege = props.montantSiege["nouveau-loyer"]
        ? props.montantSiege.fraisBail
        : props.montantSiege;
      // console.log("montSiege", montSiege);
      selectedProductList.map((selectedProduct) => {
        // console.table(tabElement);

        if (selectedProduct["nouvelle-forme-juridique"]) {
          if (
            selectedProduct["nouvelle-forme-juridique"].includes(
              "Responsabilité Limitée"
            ) ||
            selectedProduct["nouvelle-forme-juridique"].includes(
              "Responsabilité Limitée Unipersonnelle"
            ) ||
            selectedProduct["nouvelle-forme-juridique"].includes("SARLU") ||
            selectedProduct["nouvelle-forme-juridique"].includes("SARL")
          ) {
            if (prixAnnonce.includes("journal")) {
              tabElement = [
                ...tabElement,
                {
                  name: "insertion-journal",
                  value: "Insertion dans un journal d’annonce légale",
                },
              ];
              prixAnnonced = 30000;
            }
            // console.log("prixAnnonced", prixAnnonced);
            amount =
              299000 +
              montSiege +
              props.montantCapital +
              props.montantCession +
              prixAnnonced;

            tabElement = [
              ...tabElement,
              {
                name: "formalite-modification",
                value:
                  "Formalité de modification " +
                  selectedProduct["nouvelle-forme-juridique"],
              },
            ];
          }
          if (
            selectedProduct["nouvelle-forme-juridique"].includes(
              "Actions Simplifiée"
            ) ||
            selectedProduct["nouvelle-forme-juridique"].includes(
              "Actions Simplifiée Unipersonnelle"
            ) ||
            selectedProduct["nouvelle-forme-juridique"].includes(
              "Societe immo"
            ) ||
            selectedProduct["nouvelle-forme-juridique"].includes("SAS") ||
            selectedProduct["nouvelle-forme-juridique"].includes("SASU") ||
            selectedProduct["nouvelle-forme-juridique"].includes("SCI")
          ) {
            if (prixAnnonce.includes("journal")) {
              tabElement = [
                ...tabElement,
                {
                  name: "insertion-journal",
                  value: "Insertion dans un journal d’annonce légale",
                },
              ];
              prixAnnonced = 30000;
            }
            amount =
              499000 +
              montSiege +
              props.montantCapital +
              props.montantCession +
              prixAnnonced;

            tabElement = [
              ...tabElement,
              {
                name: "formalite-modification",
                value:
                  "Formalité de modification " +
                  selectedProduct["nouvelle-forme-juridique"],
              },
            ];

            // pricedProductList.push(...tabElement);
          }
          if (
            selectedProduct["nouvelle-forme-juridique"].includes("Individuelle")
          ) {
            amount =
              120000 + montSiege + props.montantCapital + props.montantCession;
            tabElement = [
              ...tabElement,
              {
                name: "formalite-modification",
                value:
                  "Formalité de modification " +
                  selectedProduct["nouvelle-forme-juridique"],
              },
            ];

            // pricedProductList.push(...tabElement);
          }
          // console.log("prixAnnonced", prixAnnonced);
          // console.log("amount", amount);
          // console.table(tabElement);

          pricedProductList.push(...tabElement);
        }
      });

      const taxRate = getTaxAmount(props.currency) / 100 + 1;

      setDynamicProductList([...pricedProductList]);
      setNetAmount(amount);
      props.setAmount(Math.round(amount * taxRate));
    } else {
      selectedProductList.forEach((selectedProduct) => {
        const key = Object.keys(selectedProduct)[0];
        if (props.paraPays === "ci") {
          if (Object.values(selectedProduct)[0] === "Abidjan") {
            // data.options.unshift(optionDomicili);
            pricedProductList = pricedProductList.filter(
              (data) => !data.name.includes("offre")
            );
            if (
              props.demande.toLowerCase().includes("sas") ||
              props.demande.toLowerCase().includes("sasu") ||
              props.demande.toLowerCase().includes("sci-commerciale") ||
              props.demande.toLowerCase().includes("commerciale")
            ) {
              pricedProductList.push({
                name: "offre",
                value: "Frais de constitution ",
                price: props.price[0].montant ,
              });
            }

            if (
              props.demande.toLowerCase().includes("sci-civile") ||
              props.demande.toLowerCase().includes("civile")
            ) {
              pricedProductList.push({
                name: "offre",
                value: "Frais de constitution ",
                price: props.price[0].montant ,
              });
            }

            if (
              props.demande.toLowerCase().includes("ong") ||
              props.demande.toLowerCase().includes("association") ||
              props.demande.toLowerCase().includes("fondation")
            ) {
              pricedProductList.push({
                name: "offre",
                value: "Frais de constitution",
                price: props.price[0].montant ,
              });
            }

            if (props.demande.toLowerCase().includes("scoop")) {
              pricedProductList.push({
                name: "offre",
                value: "Frais de constitution",
                price: props.price[0].montant ,
              });
            }

            if (
              props.demande.toLowerCase().includes("sarl") ||
              props.demande.toLowerCase().includes("sarlu")
            ) {
              pricedProductList.push({
                name: "offre",
                value: "Frais de constitution",
                price: props.price[0].montant ,
              });
            }
            if (props.demande.toLowerCase().includes("individuelle")) {
              pricedProductList.push({
                name: "offre",
                value: "Frais de constitution",
                price: props.price[0].montant ,
              });
            }
          }
          if (Object.values(selectedProduct)[0] === "Hors d'Abidjan") {
            pricedProductList = pricedProductList.filter(
              (data) => !data.name.includes("offre")
            );
            // console.log('pricedProductList',pricedProductList);

            if (
              props.demande.toLowerCase().includes("sas") ||
              props.demande.toLowerCase().includes("sasu") ||
              props.demande.toLowerCase().includes("sci-commerciale") ||
              props.demande.toLowerCase().includes("commerciale")
            ) {
              pricedProductList.push({
                name: "offre",
                value: "Frais de constitution",
                price: props.price[1].montant ,
              });
            }
            if (
              props.demande.toLowerCase().includes("ong") ||
              props.demande.toLowerCase().includes("association") ||
              props.demande.toLowerCase().includes("fondation")
            ) {
              pricedProductList.push({
                name: "offre",
                value: "Frais de constitution ",
                price: props.price[1].montant,
              });
            }
            if (props.demande.toLowerCase().includes("scoop")) {
              pricedProductList.push({
                name: "offre",
                value: "Frais de constitution ",
                price: props.price[1].montant,
              });
            }

            if (
              props.demande.toLowerCase().includes("sarl") ||
              props.demande.toLowerCase().includes("sarlu")
            ) {
              pricedProductList.push({
                name: "offre",
                value: "Frais de constitution ",
                price: props.price[1].montant,
              });
            }
            if (
              props.demande.toLowerCase().includes("sci-civile") ||
              props.demande.toLowerCase().includes("civile")
            ) {
              pricedProductList.push({
                name: "offre",
                value: "Frais de constitution ",
                price: props.price[1].montant,
              });
            }

            if (props.demande.toLowerCase().includes("individuelle")) {
              pricedProductList.push({
                name: "offre",
                value: "Frais de constitution ",
                price: props.price[1].montant,
              });
            }
          }
        }

        if (props.paraPays === "bn") {
          pricedProductList = pricedProductList.filter(
            (data) => !data.name.includes("offre")
          );
          if (props.demande.toLowerCase().includes("individuelle")) {
            pricedProductList.push({
              name: "offre",
              value: "Frais de constitution ",
              price: 52_000,
            });
          }
        }      

        if (props.paraPays === "bf") {
          pricedProductList = pricedProductList.filter(
            (data) => !data.name.includes("offre")
          );
          if (props.demande.toLowerCase().includes("individuelle")) {
            pricedProductList.push({
              name: "offre",
              value: "Frais de constitution ",
              price: 99_000,
            });
          }

          if (
            props.demande.toLowerCase().includes("sarl") ||
            props.demande.toLowerCase().includes("sarlu")
          ) {
            pricedProductList.push({
              name: "offre",
              value: "Frais de constitution ",
              price: 250_000,
            });
          }
        }

        if (props.paraPays === "rdc") {
          pricedProductList = pricedProductList.filter(
            (data) => !data.name.includes("offre")
          );
          if (props.demande.toLowerCase().includes("individuelle")) {
            pricedProductList.push({
              name: "offre",
              value: "Frais de constitution ",
              price: 280,
            });
          }

          if (
            props.demande.toLowerCase().includes("sarl") ||
            props.demande.toLowerCase().includes("sarlu") ||
            props.demande.toLowerCase().includes("sas") ||
            props.demande.toLowerCase().includes("sasu")
          ) {
            pricedProductList.push({
              name: "offre",
              value: "Frais de constitution ",
              price: 1000,
            });
          }
        }

        productList.forEach((product) => {
          // console.log('product',product);

          if (key !== product.name) return;

          const option = product.options.find(
            (option) => option.value === selectedProduct[key]
          );
          // console.log("option", option);
          if (!option || !option.price) return;

          pricedProductList.push({
            name: product.name,
            value: option.value,
            price: option?.price,
          });

          // console.log("pricedProductList11", pricedProductList);
        });
      });
      // console.log("pricedProductList22", pricedProductList);
      const amount = pricedProductList.reduce(
        (car, value) => (car += value.price),
        0
      );
      const taxRate = getTaxAmount(props.currency) / 100 + 1;

      setDynamicProductList([...pricedProductList]);
      setNetAmount(amount);
      props.setAmount(Math.round(amount * taxRate));
    }
  }, [selectedProductList, productList]);

  useEffect(() => {
    initalize();
  }, [initalize]);

  return (
    <>
      <div className="px-5 mt-10">
        <div className="font-medium text-center text-lg">
          Résumé de votre commande
        </div>
      </div>
      <div className="pos intro-y grid grid-cols-12 gap-5 mt-5 px-5">
        <div className="intro-y col-span-12 text-center ">
          <Alert variant="danger">
            Vous avez la possibilité de payer plus tard depuis votre espace
            client ou en vous rendant à nos bureaux.
          </Alert>
        </div>
        <div className="col-span-12 lg:col-span-9">
          <div className="tab-content">
            <div className="tab-content__pane active" id="ticket">
              {dynamicProductList.map((product, index) => {
                return (
                  <div className="pos__ticket box p-2 mt-5" key={index}>
                    <div
                      className="flex items-center p-3 transition duration-300 
                                    ease-in-out bg-white dark:bg-dark-3 hover:bg-gray-200 
                                    dark:hover:bg-dark-1 rounded-md"
                    >
                      <div className="pos__ticket__item-name truncate mr-1">
                        {product.value}
                      </div>
                      <div className="ml-auto">
                        {props.demande.includes("statutaire")
                          ? ""
                          : `${product.price} ${props.currency ?? "FCFA"} `}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <div className="box p-5 mt-5">
            <div className="flex">
              <div className="mr-auto">Sous-total</div>
              <div>
                {netAmount} {props.currency ?? "FCFA"}
              </div>
            </div>
            <div className="flex mt-2">
              <div className="mr-auto">TVA</div>
              <div>{getTaxAmount(props.currency)}%</div>
            </div>
            <div className="flex mt-4 pt-4 border-t border-gray-200 dark:border-dark-5">
              <div className="mr-auto font-medium text-base">Total</div>
              <div className="font-medium text-base">
                {props.amount} {props.currency ?? "FCFA"}
              </div>
            </div>
          </div>
          <div className="flex mt-5 ">
            {/* <button
              className="button w-32 border border-gray-400 dark:border-dark-5 
                        text-gray-600 dark:text-gray-300"
              onClick={props.handleBackClick}
            >
              Payer
            </button> */}

            {props.moyen_paiements?.map((moyen_paiement, index) => {
              if (
                (props.currency === "MAD" || props.currency === "USD") &&
                moyen_paiement.libelle.toLowerCase().includes("mobile")
              )
                return null;
              if (moyen_paiement.libelle.includes("Payer plus tard")) {
                return (
                  <div className="intro-y flex" key={index}>
                    <button
                      type="button"
                      className="button button--lg block text-white border border-gray-400
                      text-gray-600  mx-auto mt-8"
                      disabled={props.isDisabled}
                      onClick={(e) =>
                        props.handlePaymentClick(e, moyen_paiement)
                      }
                    >
                      {props.isDisabled ? "En cours..." : " Payer plus tard"}
                    </button>
                  </div>
                );
              }
            })}
            <button
              className="button button--lg block text-white bg-theme-1 
              ml-4 mt-8"
              onClick={props.handleValidateClick}
              disabled={props.isDisabled}
            >
              {props.isDisabled ? "En cours..." : "Payer maintenant"}
            </button>
          </div>
        </div>
        <div className="intro-y grid grid-cols-12">
          <button
            className="button w-32 border border-gray-400 dark:border-dark-5 
                        text-gray-600 dark:text-gray-300"
            onClick={props.handleBackClick}
          >
            Revenir
          </button>
        </div>
      </div>
    </>
  );
}
