import React, { useEffect, useState } from "react";
// import { Components } from "..";
import {
  // ElementsConsumer,
  // CardElement,
  Elements,
  useStripe,
  useElements,
  LinkAuthenticationElement,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import { Services } from "../../services";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import Loading from "../Loader/Loading";

function CheckoutForm(props) {
  // console.log("checkout", props);
  // const { id } = useParams();

  // console.log("id", id);
  // InjectedCheckoutForm(props);
  const stripe = useStripe();
  const elements = useElements();

  const [succeeded, setSucceeded] = useState(false);
  const [message, setMessage] = useState(null);

  const [processing, setProcessing] = useState(false);
  // const [clientSecret, setClientSecret] = useState("");

  const [disabled, setDisabled] = useState(true);

  const CURRENCY = props.currency !== "FCFA" ? props.currency : "xof";
  const {
    // dataUse,
    // demande,

    usePaiement,
    handlePaymentSubmit,
  } = props;
  // console.log("usePaiement1", usePaiement);

  // const getPaymentIntent = async () => {
  //   const payment_data = {
  //     demande_id: demande ? demande : parseInt(id),
  //     montant: parseInt(usePaiement.montant),
  //     currency: CURRENCY,
  //     description: "This is test payment",
  //     payment_data: JSON.stringify({
  //       demande_id: demande ? demande : parseInt(id),
  //       montant: parseInt(usePaiement.montant),
  //       currency: CURRENCY,
  //     }),
  //   };
  //   if (dataUse) {
  //     const { client_secret } =
  //       await Services.PaiementService.getPaymentIntents(
  //         dataUse,
  //         JSON.stringify(payment_data),
  //         AbortController.signal
  //       );
  //     setClientSecret(client_secret);
  //   } else {
  //     const { client_secret } = await Services.PaiementService.getPaymentIntent(
  //       JSON.stringify(payment_data),
  //       AbortController.signal
  //     );
  //     setClientSecret(client_secret);
  //   }

  //   // console.log("getPaymentIntent", client_secret);
  // };

  // useEffect(() => {
  //   // Create PaymentIntent as soon as the page loads
  //   getPaymentIntent();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // usePaiement.setIsDisabled(true);

    if (!stripe || !elements) return;
    setProcessing(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/demandes`,
        },
        redirect: "if_required",
      });
  

      if (error) {
        setMessage(`Payment failed ${error.message}`);
        setProcessing(false);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        console.log("paymentIntent", paymentIntent);
      } else {
        setMessage("An unexpected error occured.");
        setProcessing(false);
        setSucceeded(true);
      }
      //   // const card = elements.getElement(CardElement);
      //   // const result = await stripe.createToken(card);

      //   // if (result.error) throw new Error(result.error.message);

      const payment_datas = {
        // source: result.token.id,
        payment_intent_id: paymentIntent.id,
        amount: usePaiement.montant,
        currency: CURRENCY,
        description: "This is test payment",
      };
      // console.log("payment_datas", payment_datas);

      handlePaymentSubmit(e, payment_datas);
    } catch (error) {
      usePaiement.setIsDisabled(false);
      if ("messages" in error) return; //toast
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setMessage(event.error ? event.error.message : "");
  };
  // console.log("usePaiement2", usePaiement);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <div>
      <h3 className="mb-3" style={{ fontSize: "1.5rem" }}>
        <span className="font-light">Montant à payer: </span>
        <span className="text-gray-800 font-bold">
          {usePaiement.montant} {CURRENCY === "XOF" ? "FCFA" : CURRENCY}
        </span>
      </h3>
      {/* {clientSecret ? ( */}
      <form form id="payment-form" onSubmit={handleSubmit}>
        <h3 className="text-lg font-medium">informations</h3>

        <LinkAuthenticationElement />
        {/* <h3 className="text-lg font-medium">Expédition</h3> */}
        <AddressElement options={{ mode: "shipping" }} />
        <h3 className="text-lg font-medium">Paiement</h3>

        <PaymentElement id="payment-element" />

        {/* <CardElement options={cardStyle} onChange={handleChange} /> */}
        <button
          className="btn-pay"
          type="submit"
          disabled={processing || !stripe || !elements}
        >
          {processing ? "Paiement en cours..." : "Payer maintenant"}
        </button>
        {message && (
          <div
            id="payment-message"
            className="text-red-600 text-2xl font-bold my-8 text-center"
          >
            {message}
          </div>
        )}
      </form>
      {/* ) : (
        <div className="font-bold text-gray-600">
          Nous avons un soucie Veuillez réesayer
        </div>
      )} */}
    </div>
  );
}

export function InjectedCheckoutForm(props) {
  // console.log("InjectedCheckoutForm", props);

  const [clientSecret, setClientSecret] = useState("");
  const { id } = useParams();
  const CURRENCY = props.currency !== "FCFA" ? props.currency : "xof";

  const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_KEY);

  const getPaymentIntent = async () => {
    const payment_data = {
      demande_id: props.demande ? props.demande : parseInt(id),
      montant: parseInt(props.usePaiement.montant),
      currency: CURRENCY,
      description: "This is test payment",
      payment_data: JSON.stringify({
        demande_id: props.demande ? props.demande : parseInt(id),
        montant: parseInt(props.usePaiement.montant),
        currency: CURRENCY,
      }),
    };

    if (props.dataUse) {
      const { client_secret } =
        await Services.PaiementService.getPaymentIntents(
          props.dataUse,
          JSON.stringify(payment_data),
          AbortController.signal
        );
      setClientSecret(client_secret);
    } else {
      const { client_secret } = await Services.PaiementService.getPaymentIntent(
        JSON.stringify(payment_data),
        AbortController.signal
      );
      setClientSecret(client_secret);
    }
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    getPaymentIntent();
  }, []);

  const options = {
    appearance: {
      theme: "flat",
    },
    locale: "fr",
    loadStripe: true,
    clientSecret,
  };

  // console.log("getPaymentIntent", client_secret);

  return (
    <>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            demande={props.demande}
            dataUse={props.dataUse}
            handlePaymentSubmit={props.handlePaymentSubmit}
            usePaiement={props.usePaiement}
            currency={props.currency}
          />
        </Elements>
      ) : (
        <div className="flex justify-center">
          <Loading />
        </div>
      )}
    </>
    // <Elements stripe={stripePromise} options={options}>

    //     <CheckoutForm
    //       demande={props.demande}
    //       stripe={stripe}
    //       dataUse={props.dataUse}
    //       elements={elements}
    //       handlePaymentSubmit={props.handlePaymentSubmit}
    //       usePaiement={props.usePaiement}
    //       currency={props.currency}
    //     />

    // </Elements>
  );
}
